import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { startLoading, stopLoading, setModel } from "../state/slices/appSlice";
import PageWrapper from "../components/Layout/PageWrapper";
import FormGenerator, { Field } from "../components/Form";
import CFCApi from "../apis/";
import useRDV from "../hooks/useRDV";
import moment from "moment";

export default function AddRDV(props: any, state: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let date = location?.state?.date;
  const contactId = location.state?.contactId;
  const apaId = location.state?.apaId;

  if (date) {
    date = moment(date, "DD-MM-YYYY").format("YYYY-MM-DDTHH:mm:ssZ");
  }

  const updateLieuField = (name: string, value: any, formik: any) => {
    if (value?.lieu) {
      if (name === "apaLieu") {
        formik.setFieldValue("contactLieu", null, true);
      } else {
        formik.setFieldValue("apaLieu", null, true);
      }
      formik.setFieldValue(name, value.lieu, true);
    }
  };

  const [showForm, setShowForm] = useState<boolean>(false);

  const [formFields, setFormFields] = useState<Field[]>([
    {
      name: "type",
      type: "select",
      label: "Type de rendez-vous",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      handleChange: (value) => {
        if (value.label.startsWith("Terrain")) {
          formFields[1].required = true;
          formFields[2].required = false;
          formFields[7].required = true;
          formFields[8].required = false;
        } else {
          formFields[1].required = false;
          formFields[2].required = true;
          formFields[7].required = false;
          formFields[8].required = true;
        }
        setFormFields(formFields);
      },
    },
    {
      name: "apa",
      type: "select",
      label: "APA",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "type",
        values: [],
      },
      defaultValue: apaId || null,
      handleChange: (value, formik) =>
        updateLieuField("apaLieu", value, formik),
      required: true,
    },
    {
      name: "contact",
      type: "select",
      label: "Contact / Dossier",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "type",
        values: [],
      },
      defaultValue: contactId || null,
      required: true,
      handleChange: (value, formik) =>
        updateLieuField("contactLieu", value, formik),
    },
    {
      name: "appointmentDate",
      type: "date",
      disablePast: true,
      label: "Date",
      defaultValue: date || null,
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "startHour",
      type: "hour",
      label: "Heure de début",
      grid: {
        xs: 6,
        md: 3,
      },
      required: true,
    },
    {
      name: "endHour",
      type: "hour",
      label: "Heure de fin",
      grid: {
        xs: 6,
        md: 3,
      },
      required: true,
    },
    {
      name: "guests",
      type: "tag",
      label: "Invités",
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
    },
    {
      name: "apaLieu",
      type: "text",
      label: "Lieu",
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "type",
        values: [],
      },
      required: true,
      isReadOnly: false,
    },
    {
      name: "contactLieu",
      type: "text",
      label: "Lieu",
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "type",
        values: [],
      },
      required: true,
      isReadOnly: false,
    },
  ]);

  const { getFormDynamicValues } = useRDV(formFields);

  useEffect(() => {
    (async () => {
      const updatedFromFields = await getFormDynamicValues();

      // AutoFill fields (rdv type, name and lieu) based on Contact or APA data;
      if (contactId || apaId) {
        const labelName = contactId ? "Client - Signature ODP" : "Terrain - R1";
        const currentApointmentType = updatedFromFields[0].options.find(
          (opt: { label: string; value: string }) => opt.label === labelName
        );
        updatedFromFields[0].defaultValue = currentApointmentType.value;
        const guessFiledIndex = contactId ? 2 : 1;
        const lieuFieldIndex = contactId ? 8 : 7;
        const selectedContact = updatedFromFields[guessFiledIndex].options.find(
          (opt: any) => opt.value === (contactId || apaId)
        );
        updatedFromFields[lieuFieldIndex].defaultValue = selectedContact.lieu;
      }

      setShowForm(false);
      setFormFields(updatedFromFields);
      setShowForm(true);
    })();
  }, []);

  const handleFormSubmit = async (values: {
    [key: string]: string | string[];
  }) => {
    if (values.apaLieu) {
      values.url = values.apaLieu;
      delete values.contact;
    }
    if (values.contactLieu) {
      values.url = values.contactLieu;
      delete values.apa;
    }
    delete values.contactLieu;
    delete values.apaLieu;

    try {
      dispatch(startLoading());

      const response = await CFCApi.post("/appointments", values);

      if (response) {
        dispatch(
          setModel({
            type: "success",
            content: "Prise de rendez-vous validée",
            onClose: () => {
              navigate("/");
              dispatch(setModel(null));
            },
          })
        );
      } else {
        dispatch(
          setModel({
            type: "error",
            content: "An unexpected error occurred while creating RDV",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (error: any) {
      dispatch(
        setModel({
          type: "error",
          content: error.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  return (
    <PageWrapper>
      <h1>Créer un rendez-vous</h1>
      {showForm && (
        <FormGenerator
          fields={formFields}
          submitButtonText="Sauvegarder"
          widthReturn
          onSubmit={handleFormSubmit}
        />
      )}
    </PageWrapper>
  );
}
