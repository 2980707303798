import { useEffect, useState } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import CFCApi from "../apis";
import CFCApiHydra from "../apis/CFCApiHydra";
import FormGenerator, { Field } from "../components/Form";
import { Grid } from "@mui/material";
import List from "../components/List";
import Pagination from "../components/Pagination";
import areObjectsEqual from "../helpers/checkObjectsEqual";
import extractIdFromStrings from "../helpers/extractIdFromStrings";
import useTrigger from "../hooks/useTrigger";
import useUsers from "../hooks/useUsers";

export default function EditStructure() {
  const navigate = useNavigate();
  const { triggerLoading, triggerModel, triggerError } = useTrigger();
  const { isAdmin } = useUsers();

  const [isASG, setIsASG] = useState<boolean>(false);
  const [defaultLoaded, setDefaultLoaded] = useState<boolean>(false);

  let {
    state: { contactData },
  } = useLocation();

  const [apaRattacheData, setApaRattacheData] = useState<any[]>([]);
  const [dossierRattacheData, setDossierRattacheData] = useState<any[]>([]);
  const [contactRattacheData, setContactRattacheData] = useState<any[]>([]);

  const autoFillRattachementOptions = async (
    parentValue: string | null,
    formik: any,
    structureData: any,
    typeNameValue?: string | null
  ) => {
    const allowAutoFill = localStorage.getItem("allowAutoFill");
    if (allowAutoFill === "false") return;
    const sameTypeName =
      (typeNameValue || formik.values.typeName) === structureData.typeName;
    const samestrucutre =
      formik.values.structure === structureData.structure["@id"];

    if (samestrucutre && sameTypeName) {
      formik.setFieldValue("parent", structureData.parent["@id"]);
    } else {
      formik.setFieldValue("parent", null);
    }

    setTimeout(() => {
      formik.setErrors({ ...formik.errors, parent: null });
    }, 0);

    if (!parentValue && !typeNameValue) return;

    typeNameValue = typeNameValue || formik.values.typeName;

    if (!typeNameValue || typeNameValue === "") {
      return;
    }

    let formFieldsUpdated = [...formFields];
    let parentValueId;
    if (parentValue) {
      parentValueId = extractIdFromStrings(parentValue);
    } else if (formik.values.structure) {
      parentValueId = extractIdFromStrings(formik.values.structure);
    } else {
      return;
    }

    triggerLoading(true);
    const structures = await CFCApiHydra.get(
      `/structures/subStructures?pagination=false&dropDown=1&id=${parentValueId}&childTypeName=${typeNameValue}&structureId=${contactData.id}`
    );

    // Site is the last Level, no need for Rattachement
    if (typeNameValue === "Site") {
      formFieldsUpdated[2].required = false;
    } else {
      formFieldsUpdated[2].required = true;
    }

    try {
      if (structures.substructures && structures.substructures.length > 0) {
        formFieldsUpdated[2].label =
          structures.substructures[0].typeName || "Rattachement";
        formFieldsUpdated[2].options = structures.substructures.map(
          (opt: any) => ({
            label: opt.name,
            value: opt["@id"],
          })
        );
      } else if (structures?.groupedStructures?.groupNames) {
        let formFieldsUpdated = [...formFields];
        const groupes = structures?.groupedStructures?.groupNames;
        const myGroupedOptions: any = [];
        groupes.forEach((group: any) => {
          const groupName = group.groupeName;
          myGroupedOptions.push({
            label: groupName,
            groupName: groupName,
            options: group.data.map((item: any) => {
              return { label: item.name, value: item["@id"] };
            }),
          });
        });
        formFieldsUpdated[2].label = "Rattachement";
        formFieldsUpdated[2].options = myGroupedOptions;
        setFormFields(formFieldsUpdated);
      } else {
        formFieldsUpdated[2].label = "Rattachement";
        formFieldsUpdated[2].options = [];
      }
    } catch (error) {
      triggerModel("error", "Une erreur inattendue.");
    }
    setFormFields(formFieldsUpdated);
    triggerLoading(false);
  };

  const handleFormSubmit = async (values: {
    [key: string]: string | string[];
  }) => {
    try {
      triggerLoading(true);
      const StructureEditResponse = await CFCApi.patch(
        `/structures/${contactData.id}`,
        values
      );

      if (StructureEditResponse) {
        triggerModel("success", "Mis à jour avec succés", () => {
          navigate("/apa");
        });
      } else {
        triggerModel(
          "error",
          "Une erreur inattendue s'est produite lors de la mise à jour du strucutre"
        );
      }
    } catch (error: any) {
      triggerError(error);
    } finally {
      triggerLoading(false);
    }
  };

  const [formFields, setFormFields] = useState<Field[]>([
    {
      name: "typeName",
      type: "select",
      label: "",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isReadOnly: false,
    },
    {
      name: "structure",
      type: "abstractSelect",
      label: "Structure Représentative",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
      isReadOnly: true,
    },
    {
      name: "parent",
      type: "abstractSelect",
      label: "Rattachement",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isReadOnly: false,
    },
    {
      name: "name",
      type: "text",
      label: "Nom",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "city",
      type: "text",
      label: "Ville",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "zipCode",
      type: "text",
      label: "Code postal",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "address",
      type: "text",
      label: "Adresse",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "salePointPhone",
      type: "tel",
      label: "Ligne directe PDV",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "salePointEmail",
      type: "email",
      label: "Email PDV",
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
    },
    {
      name: "platformNumber",
      type: "tel",
      label: "Numéro plateforme numérique",
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
    },
    {
      name: "shouldRemunerateApa",
      type: "radioTwoOptions",
      label: "Apporteur d’affaire à rémunérer",
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
    },
  ]);

  useEffect(() => {
    const formFieldsUpdated = JSON.parse(JSON.stringify(formFields));

    formFieldsUpdated[0].isReadOnly = !isAdmin;
    formFieldsUpdated[0].required = !isAdmin;

    setFormFields(formFieldsUpdated);
  }, [isAdmin]);

  useEffect(() => {
    localStorage.setItem("allowAutoFill", "false");
    triggerLoading(true);

    const getFormDynamicValues = async () => {
      Promise.all([
        CFCApiHydra.get(`/structures/${contactData.id}`),

        CFCApiHydra.get(
          `/structures/levels?pagination=false&all=true&structure=${contactData.id}`
        ),

        CFCApiHydra.get(
          "/structures/subStructures?pagination=false&dropDown=1&id=0"
        ),
      ])
        .then(async ([structureResponse, TypeNames, structures]) => {
          const checkIsASG = /\b(Agence|Site|Groupe)\b/.test(
            structureResponse.typeName
          );

          if (checkIsASG) {
            setIsASG(true);
          }

          try {
            if (
              structures.substructures &&
              structures.substructures.length > 0
            ) {
              let formFieldsUpdated = [...formFields];
              formFieldsUpdated[1].options = structures.substructures.map(
                (opt: any) => ({
                  label: opt.name,
                  value: opt["@id"],
                })
              );
              setFormFields(formFieldsUpdated);
            }

            if (TypeNames["hydra:member"]) {
              let formFieldsUpdated = [...formFields];

              const formatedOptions = TypeNames["hydra:member"].map(
                (opt: any) => {
                  const currentTypeName = structureResponse.typeName;

                  const disableAll = !/\b(Agence|Site)\b/.test(currentTypeName);
                  if (disableAll) {
                    formFieldsUpdated[0].isDisabled = true;
                  }
                  if (disableAll) {
                    return {
                      label: opt.label,
                      value: opt.value,
                    };
                  } else {
                    return {
                      label: opt.label,
                      value: opt.value,
                      disabled: !/\b(Agence|Site)\b/.test(opt.value),
                    };
                  }
                }
              );

              formFieldsUpdated[0].options = formatedOptions.filter(
                (option: any) => !option.disabled
              );

              setFormFields(formFieldsUpdated);
            } else {
              triggerModel(
                "error",
                "Une erreur inattendue s'est produite lors de la récupération des données."
              );
            }

            if (structureResponse) {
              const formFieldsUpdated = formFields;

              for (let key in structureResponse) {
                switch (key) {
                  case "typeName":
                    formFieldsUpdated[0].defaultValue = structureResponse[key];
                    formFieldsUpdated[0].handleChange = (selected, formik) => {
                      autoFillRattachementOptions(
                        structureResponse.structureRep,
                        formik,
                        structureResponse,
                        selected?.value
                      );
                    };
                    break;
                  case "structure":
                    formFieldsUpdated[1].defaultValue =
                      structureResponse.structureRep;
                    break;
                  case "parent":
                    triggerLoading(true);
                    const structures = await CFCApiHydra.get(
                      `/structures/subStructures?pagination=false&dropDown=1&id=${extractIdFromStrings(
                        structureResponse.structureRep
                      )}&childTypeName=${
                        structureResponse.typeName
                      }&structureId=${contactData.id}`
                    );

                    if (structures?.substructures?.length > 0) {
                      formFieldsUpdated[2].label =
                        structures.substructures[0].typeName || "Rattachement";
                      formFieldsUpdated[2].options =
                        structures.substructures.map((opt: any) => ({
                          label: opt.name,
                          value: opt["@id"],
                        }));
                    } else if (structures.groupedStructures) {
                      let formFieldsUpdated = [...formFields];
                      const groupes = structures?.groupedStructures?.groupNames;
                      const myGroupedOptions: any = [];
                      groupes.forEach((group: any) => {
                        const groupName = group.groupeName;
                        myGroupedOptions.push({
                          label: groupName,
                          groupName: groupName,
                          options: group.data.map((item: any) => {
                            return { label: item.name, value: item["@id"] };
                          }),
                        });
                      });
                      formFieldsUpdated[2].label = "Rattachement";
                      formFieldsUpdated[2].options = myGroupedOptions;
                      setFormFields(formFieldsUpdated);
                    } else {
                      formFieldsUpdated[2].label = "Rattachement";
                      formFieldsUpdated[2].options = [];
                    }

                    formFieldsUpdated[2].defaultValue =
                      structureResponse[key]["@id"];
                    formFieldsUpdated[2].trigger = {
                      name: "structure",
                      triggerFunc: (val: any, formik) => {
                        return autoFillRattachementOptions(
                          val,
                          formik,
                          structureResponse,
                          null
                        );
                      },
                    };
                    break;

                  case "name":
                    formFieldsUpdated[3].defaultValue = structureResponse[key];
                    break;

                  case "city":
                    formFieldsUpdated[4].defaultValue = structureResponse[key];
                    break;
                  case "zipCode":
                    formFieldsUpdated[5].defaultValue = structureResponse[key];
                    break;
                  case "address":
                    formFieldsUpdated[6].defaultValue = structureResponse[key];
                    break;
                  case "salePointPhone":
                    formFieldsUpdated[7].defaultValue = structureResponse[key];
                    break;
                  case "salePointEmail":
                    formFieldsUpdated[8].defaultValue = structureResponse[key];
                    break;
                  case "platformNumber":
                    formFieldsUpdated[9].defaultValue = structureResponse[key];
                    break;
                  case "shouldRemunerateApa":
                    formFieldsUpdated[10].defaultValue = structureResponse[key];
                    break;
                  default:
                }
              }

              setFormFields(formFieldsUpdated);

              // hide field parent if strucutre not contains preant (level 0)
              if (!structureResponse.parent) {
                let formFieldsUpdated = [...formFields];
                formFieldsUpdated[2].required = false;
                formFieldsUpdated[2].isHidden = true;
                setFormFields(formFieldsUpdated);
              }

              // if it's not agence/site/groupe, update first field lable, remove vlaidation and hide last 3 fields
              if (!checkIsASG) {
                let formFieldsUpdated = [...formFields];
                formFieldsUpdated[0].label = "Haut niveau structurel ";
                formFieldsUpdated[4].required = false;
                formFieldsUpdated[5].required = false;
                formFieldsUpdated[6].required = false;
                formFieldsUpdated[7].required = false;
                formFieldsUpdated[8].required = false;
                formFieldsUpdated[8].isHidden = true;
                formFieldsUpdated[9].required = false;
                formFieldsUpdated[9].isHidden = true;
                formFieldsUpdated[10].required = false;
                formFieldsUpdated[10].isHidden = true;
              } else {
                formFieldsUpdated[0].label = "Agence/Site/Groupe";
              }

              setFormFields(formFieldsUpdated);
            } else {
              triggerModel(
                "error",
                "Une erreur inattendue s'est produite lors de la récupération des données."
              );
            }
          } catch (error: any) {
            throw error;
          }
        })
        .catch((error: any) => {
          triggerError(error);
        })
        .finally(() => {
          setDefaultLoaded(true);
          triggerLoading(false);
        });
    };
    getFormDynamicValues();
  }, []);

  useEffect(() => {
    if (defaultLoaded) {
      localStorage.setItem("allowAutoFill", "true");
      return () => {
        localStorage.removeItem("allowAutoFill");
      };
    }
  }, [defaultLoaded]);

  return (
    <PageWrapper>
      <Grid container spacing={2} display="flex" justifyContent="center">
        <Grid item xs={12} md={12}>
          <h2>
            Modification{" "}
            {isASG ? "Agence/Site/Groupe" : "Haut niveau structurel"}
          </h2>
          {defaultLoaded && (
            <FormGenerator
              fields={formFields}
              submitButtonText="Sauvegarder"
              widthReturn
              onSubmit={handleFormSubmit}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <List
            title="APA rattachés"
            items={apaRattacheData}
            navigateTo="/apa/edit-fiche-apa"
          />
          <Pagination
            apiData={`/structures/${contactData.id}/apas?allApas=true`}
            getCurrentItems={(current) => {
              const formedData = current.map((item: any) => ({
                name: `${item.firstName} ${item.lastName}`,
                id: item["@id"],
              }));
              if (!areObjectsEqual(formedData, apaRattacheData)) {
                setApaRattacheData(formedData);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <List
            title="Dossiers rattachés"
            items={dossierRattacheData}
            navigateTo="/edit-dossier"
          />
          <Pagination
            apiData={`/structures/${contactData.id}/folders`}
            getCurrentItems={(current) => {
              const formedData = current.map((item: any) => ({
                name: `${item.firstName} ${item.lastName}`,
                id: item["@id"],
              }));
              if (!areObjectsEqual(formedData, dossierRattacheData)) {
                setDossierRattacheData(formedData);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <List
            title="Contacts rattachés"
            items={contactRattacheData}
            navigateTo="/edit-contact"
          />
          <Pagination
            apiData={`/structures/${contactData.id}/contacts`}
            getCurrentItems={(current) => {
              const formedData = current.map((item: any) => ({
                name: `${item.firstName} ${item.lastName}`,
                id: item["@id"],
              }));
              if (!areObjectsEqual(formedData, contactRattacheData)) {
                setContactRattacheData(formedData);
              }
            }}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
