import React from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth="md" className="error">
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you're looking for does not exist.</p>
      <Button text="Back" size="sm" to={() => navigate("/")} />
    </Container>
  );
};

export default ErrorPage;
