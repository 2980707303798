import { useState } from "react";
import CFCApiHydra from "../apis/CFCApiHydra";
import useTrigger from "./useTrigger";

const useRDV = (initialFormField: any) => {
  const { triggerModel, triggerLoading } = useTrigger();

  const [formFields, setFormFields] = useState<any[]>(initialFormField);

  const getFormDynamicValues = async () => {
    triggerLoading(true);

    const typesResponse = await CFCApiHydra.get(
      "/appointment_types?hasParent=false&pagination=false"
    );
    const APAsRepsonse = await CFCApiHydra.get("/apas?pagination=false");
    const contactsRepsonse = await CFCApiHydra.get(
      "/contacts?all=true&pagination=false"
    );

    if (typesResponse["hydra:member"]) {
      let formFieldsUpdated = [...formFields];
      formFieldsUpdated[0].options = typesResponse["hydra:member"].map(
        (opt: any) => ({
          label: `${opt.parentType?.name ? opt.parentType.name + " - " : ""}${
            opt.name
          }`,
          value: opt["@id"],
        })
      );
      formFieldsUpdated[1].parent = {
        depend: "type",
        values: [],
      };

      formFieldsUpdated[2].parent = {
        depend: "type",
        values: [],
      };

      formFieldsUpdated[7].parent = {
        depend: "type",
        values: [],
      };

      formFieldsUpdated[8].parent = {
        depend: "type",
        values: [],
      };

      typesResponse["hydra:member"].forEach((opt: any) => {
        if (opt.parentType?.name === "Terrain") {
          formFieldsUpdated[1].parent?.values.push(opt["@id"]);
          formFieldsUpdated[7].parent?.values.push(opt["@id"]);
        }
      });
      typesResponse["hydra:member"].forEach((opt: any) => {
        if (opt.parentType?.name === "Client") {
          formFieldsUpdated[2].parent?.values.push(opt["@id"]);
          formFieldsUpdated[8].parent?.values.push(opt["@id"]);
        }
      });
      setFormFields(formFieldsUpdated);
    } else {
      triggerModel(
        "error",

        "An unexpected error occurred while getting type de contact"
      );
    }

    triggerLoading(false);

    if (APAsRepsonse["hydra:member"]) {
      let formFieldsUpdated = [...formFields];

      formFieldsUpdated[1].options = APAsRepsonse["hydra:member"].map(
        (opt: any) => {
          let lieu = opt.address || "";
          const { address, zipCode, city } = opt?.structure;
          if (address || zipCode || city) {
            lieu = [address, zipCode, city]
              .filter((v) => v !== null && v !== undefined)
              .join(", ");
          }
          return {
            label: `${opt.firstName} ${opt.lastName}`,
            value: opt["@id"],
            lieu,
          };
        }
      );
      setFormFields(formFieldsUpdated);
    } else {
      triggerModel(
        "error",

        "An unexpected error occurred while getting APAs"
      );
    }

    if (contactsRepsonse["hydra:member"]) {
      let formFieldsUpdated = [...formFields];

      formFieldsUpdated[2].options = contactsRepsonse["hydra:member"].map(
        (opt: any) => ({
          label: `${opt.firstName} ${opt.lastName}`,
          value: opt["@id"],
          lieu: opt?.address || "",
        })
      );
      setFormFields(formFieldsUpdated);
    } else {
      triggerModel(
        "error",

        "An unexpected error occurred while getting Contacts"
      );
    }
    return formFields;
  };

  return { getFormDynamicValues };
};

export default useRDV;
