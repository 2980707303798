import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

import TextField from "./TextField";
import DateField from "./DateField";
import HourField from "./HourField";
import LinkInputField from "./LinkInputField";
import PasswordField from "./PasswordField";
import Abstract from "./AbstractField";
import AbstractSelect from "./AbstractSelectField";
import SelectField from "./SelectField";
import LinkField from "./LinkField";
import TagFiled from "./TagField";
import Grid from "../Layout/Grid";
import FileField from "./FileField";
import TextAreaField from "./TextAreaField";
import HintField from "./HintField";
import UploadField from "./UploadField";
import CheckboxField from "./CheckboxField";
import RadioField from "./RadioField";
import RadioTwoOptionsField from "./RadioTwoOptionsField";

export enum OperationNames {
  "subtraction",
}

export interface Field {
  name: string;
  type:
    | "abstract"
    | "abstractSelect"
    | "hour"
    | "tag"
    | "text"
    | "select"
    | "react-select"
    | "radio"
    | "radioTwoOptions"
    | "checkbox"
    | "date"
    | "email"
    | "tel"
    | "password"
    | "file"
    | "textarea"
    | "link"
    | "hint"
    | "upload"
    | "number"
    | "link_input";
  isReadOnly?: boolean;
  isDisabled?: boolean;
  noOptionsMessage?: any;
  isHidden?: boolean;
  highlight?: boolean;
  trigger?: {
    name: string;
    typeName?: string;
    triggerFunc: (
      val: string,
      formik?: any
    ) =>
      | {
          label: string | null;
          value: string | null;
        }
      | any;
  };
  label: string;
  required?: boolean;
  url?: string;
  options?: { label: string; value: any }[] | any[];
  menuIsOpen?: boolean;
  defaultValue?: any;
  value?: any;
  isMulti?: boolean;
  isEditable?: boolean;
  data?: any;
  grid?: {
    xs: number;
    md: number;
  };
  tooltip?: string;
  action?: any;
  uploadTypes?: { label: string; value: string }[];
  uploadAction?: any;
  concatData?: boolean;
  parent?: {
    depend: string;
    values: string[];
  };
  accept?: string;
  placeholder?: string;
  disablePast?: boolean;
  disableFuture?: boolean;
  handleChange?: (...value: any) => void;
  details?: any;
  prefix?: any;
  operation?: {
    name: OperationNames;
    targetField: string;
    fields: [string, string];
  };
}

interface FormGeneratorProps {
  fields: Field[];
  initialValues?: any;
  submitButtonText?: string;
  nextButtonDisabled?: boolean;
  widthReturn?: boolean;
  widthShare?: boolean;
  shareAction?: any;
  isReadOnly?: boolean;
  onShowResults?: any;
  onShowNext?: any;
  onSubmit?: (
    values: { [key: string]: string | string[] },
    formik?: any
  ) => void;
  onChange?: (values: { [key: string]: string | string[] }) => void;
  handleReturn?: any;
  data?: any;
}

const GridWrapper = (props: any) => {
  return <Grid {...props}>{props.children}</Grid>;
};

const excludedFieldTypes = ["link", "link_inputi", "hint", "upload"]; //  ['link', 'paragraph'] to exclude more fields

const FormGenerator: React.FC<FormGeneratorProps> = ({
  fields,
  initialValues = null,
  submitButtonText,
  nextButtonDisabled,
  widthReturn,
  widthShare,
  shareAction,
  onShowResults,
  onShowNext,
  onSubmit,
  onChange,
  isReadOnly,
  handleReturn,
  ...data
}) => {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape(
    fields.reduce((schema: any, field) => {
      if (field.required) {
        schema[field.name] = Yup.string().required(`Ce champ est obligatoire`);
      }
      if (field.required && field.parent?.depend && field.parent?.values) {
        schema[field.name] = Yup.string().when(field.parent.depend, {
          // @ts-ignore: Unreachable code error
          is: (value: any) => field.parent.values.includes(value),
          then: () => Yup.string().required("Ce champ est obligatoire"),
          otherwise: () => Yup.string(),
        });
      }
      if (field.type === "tag") {
        if (field.required) {
          schema[field.name] = Yup.array()
            .of(Yup.string().email("Adresse Email invalide\n"))
            .min(
              1,
              "Au moins une adresse email est requise. Entrez les adresses séparées par des virgules, des espaces"
            )
            .required(`Ce champ est obligatoire`);
        } else {
          schema[field.name] = Yup.array().of(
            Yup.string().email("Adresse Email invalide\n")
          );
        }
      }
      if (field.type === "email") {
        schema[field.name] = Yup.string().email("Adresse e-mail invalide");
        if (field.required) {
          schema[field.name] = Yup.string()
            .required(`Ce champ est obligatoire`)
            .email("Adresse e-mail invalide");
        }
      }
      if (field.type === "tel") {
        schema[field.name] = Yup.string().matches(
          /^0[0-9]{9}$/,
          "Format de numéro de téléphone invalide"
        );
        if (field.required) {
          schema[field.name] = Yup.string()
            .required(`Ce champ est obligatoire`)
            .matches(/^0[0-9]{9}$/, "Format de numéro de téléphone invalide");
        }
      }
      if (field.isMulti) {
        if (field.required) {
          schema[field.name] = Yup.array().required(`Ce champ est obligatoire`);
        }
      }
      return schema;
    }, {})
  );

  const handleFormSubmit = (values: { [key: string]: string | string[] }) => {
    if (!onSubmit) {
      return;
    }
    // Remove excluded field types from the form values before submitting
    const valuesWithoutExcludedTypes = Object.keys(values).reduce(
      (result, key) => {
        const field = fields.find((f) => f.name === key);
        if (field && !excludedFieldTypes.includes(field.type)) {
          result[key] = values[key];
        }
        return result;
      },
      {} as { [key: string]: string | string[] }
    );

    // Call the provided onSubmit function
    onSubmit(valuesWithoutExcludedTypes, formik);
  };

  const formik = useFormik({
    initialValues:
      initialValues ||
      fields.reduce<{ [key: string]: string | string[] }>((values, field) => {
        values[field.name] =
          field.type === "checkbox" ? [] : field.defaultValue;
        return values;
      }, {}),
    validationSchema,
    onSubmit: handleFormSubmit,
    validate: onChange,
  });

  const renderField = (field: Field, values: any) => {
    if (field?.parent) {
      if (!field.parent.values.includes(values[field.parent.depend])) {
        return;
      }
    }
    const generateField = () => {
      switch (field.type) {
        case "text":
        case "number":
          return <TextField key={field.name} field={field} formik={formik} />;
        case "abstract":
          return <Abstract key={field.name} field={field} formik={formik} />;
        case "abstractSelect":
          return (
            <AbstractSelect key={field.name} field={field} formik={formik} />
          );
        case "select":
          return <SelectField key={field.name} field={field} formik={formik} />;
        case "date":
          return <DateField key={field.name} field={field} formik={formik} />;
        case "hour":
          return <HourField key={field.name} field={field} formik={formik} />;
        case "email":
          return <TextField key={field.name} field={field} formik={formik} />;
        case "tel":
          return <TextField key={field.name} field={field} formik={formik} />;
        case "password": // Added 'password' case
          return (
            <PasswordField key={field.name} field={field} formik={formik} />
          );
        case "file":
          return <FileField key={field.name} field={field} formik={formik} />;
        case "upload":
          return <UploadField key={field.name} field={field} formik={formik} />;
        case "textarea":
          return (
            <TextAreaField key={field.name} field={field} formik={formik} />
          );
        case "checkbox":
          return (
            <CheckboxField key={field.name} field={field} formik={formik} />
          );
        case "radio":
          return <RadioField key={field.name} field={field} formik={formik} />;
        case "radioTwoOptions":
          return (
            <RadioTwoOptionsField
              key={field.name}
              field={field}
              formik={formik}
            />
          );
        case "link":
          return (
            <LinkField
              key={field.name}
              label={field.label}
              formik={formik}
              url={field.url}
            />
          );
        case "hint":
          return <HintField key={field.name} label={field.label} />;
        case "link_input":
          return <LinkInputField key={field.name} formik={formik} {...field} />;
        case "tag":
          return <TagFiled key={field.name} field={field} formik={formik} />;

        default:
          return null;
      }
    };
    return (
      <GridWrapper
        key={field.name}
        xs={field?.grid?.xs || 12}
        md={field?.grid?.md || 12}
        style={{ display: field.isHidden ? "none" : "inhiret" }}
      >
        {generateField()}
      </GridWrapper>
    );
  };

  const handleShowResults = (formValues: any) => {
    if (onShowResults) {
      onShowResults(formValues);
    }
  };

  const handleNextResults = (formValues: any) => {
    if (onShowNext) {
      onShowNext(formValues);
    }
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={`form${isReadOnly ? " form-readonly" : ""}`}
      autoComplete="off"
    >
      <Grid className="form-generator" container columnSpacing={2}>
        {fields.map((field: any) => renderField(field, formik.values), formik)}
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid xs={12} md={12}>
          <div className="form-actions form-actions-mb">
            {widthReturn && (
              <Button
                text="RETOUR"
                onPress={() => (handleReturn ? handleReturn() : navigate(-1))}
              />
            )}
            {submitButtonText && (
              <Button block={!widthReturn} submit text={submitButtonText} />
            )}
          </div>

          {widthShare && (
            <div className="form-actions share-action">
              <Button text="PARTAGER" icon="share" onPress={shareAction} />
            </div>
          )}

          {onShowNext && (
            <div className="form-actions results-action">
              <Button
                block
                text="Suivant"
                disabled={nextButtonDisabled}
                onPress={() => handleNextResults(formik.values)}
              />
            </div>
          )}

          {onShowResults && (
            <div className="form-actions results-action">
              <Button
                block
                text="AFFICHER LES RÉSULTATS"
                onPress={() => handleShowResults(formik.values)}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default FormGenerator;
