import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading, setModel } from "../state/slices/appSlice";
import CFCApi from "../apis";
import PageWrapper from "../components/Layout/PageWrapper";
import FormGenerator from "../components/Form";
import decode from "../helpers/JWTDecode";
import Cookie from "js-cookie";
import { setUser } from "../state/slices/userSlice";

export default function Profile() {
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState<any>(null);

  interface UserData {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  }

  useEffect(() => {
    (async () => {
      dispatch(startLoading());
      const userToken: UserData = decode(Cookie.get("token")) as any;
      const userResponse = await CFCApi.get(`/users/${userToken.id}`);
      dispatch(setUser(userResponse));
      setFormFields([
        {
          name: "firstName",
          type: "text",
          label: "Nom",
          required: true,
          defaultValue: userResponse.firstName || null,
          isEditable: true,
        },
        {
          name: "lastName",
          type: "text",
          label: "Prénom",
          required: true,
          defaultValue: userResponse.lastName || null,
          isEditable: true,
        },
        {
          name: "email",
          type: "email",
          label: "Email",
          defaultValue: userResponse.email || null,
          required: true,
          isEditable: false,
          isReadOnly: true,
        },
        {
          name: "password",
          type: "password",
          label: "Mot de passe",
          required: true,
        },
      ]);
      dispatch(stopLoading());
    })();
  }, []);

  const handleFormSubmit = async (values: {
    [key: string]: string | string[];
  }) => {
    console.log("Form submitted with values from PROFILE:", values);
    if (!values.password || values.password === "") {
      delete values.password;
    }

    try {
      dispatch(startLoading());
      const response = await CFCApi.patch("/users/profile", values);
      const { firstName, lastName, email } = response;

      window.localStorage.setItem(
        "user",
        JSON.stringify({ firstName, lastName, email })
      );

      

      if (response) {
        dispatch(setUser(response));
        dispatch(
          setModel({
            type: "success",
            content: "mise à jour réussie",
            onClose: () => {
              dispatch(setModel(null));
            },
          })
        );
      } else {
        dispatch(
          setModel({
            type: "error",
            content: "An unexpected error occurred while logging in:",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (error: any) {
      // Login failed due to invalid credentials
      
      dispatch(
        setModel({
          type: "error",
          content: error.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  return (
    <PageWrapper>
      <div className="login-wrapper">
        <h1>Modifier profil</h1>
        {formFields && (
          <FormGenerator
            fields={formFields}
            submitButtonText="Sauvegarder"
            onSubmit={handleFormSubmit}
            widthReturn
          />
        )}
      </div>
    </PageWrapper>
  );
}
