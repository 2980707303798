import React, { FC } from "react";
import Icon from "./Icon";
import Button from "./Button";

interface ModalProps {
  type: "success" | "error";
  content: string;
  onClose: () => void;
  action?: { text: string; func: any };
}

const Modal: FC<ModalProps> = ({ type, content, onClose, action }) => {
  return (
    <>
      <div className={`modal modal-${type}`}>
        <div className="modal-wrapper">
          <div className="modal-content">
            <div className="modal-header">
              <div className="icon">
                {type === "success" ? (
                  <Icon icon="check-circle" size={38} />
                ) : (
                  <Icon icon="close-circle" size={38} />
                )}
              </div>
            </div>
            <div className="modal-body">
              <div className="text-content">{content}</div>
            </div>
            <div className="modal-footer">
              <Button text="Retour" size="sm" onPress={onClose} />
              {action && action.text && action.func && (
                <Button text={action.text} size="sm" onPress={action.func} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
