import { useEffect } from "react";
import Button from "../components/Button";
import PageWrapper from "../components/Layout/PageWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import extractIdsFromStrings from "../helpers/extractIdFromStrings";
import CFCApi from "../apis";
import CFCApiHydra from "../apis/CFCApiHydra";
import NomenclatureForm from "../components/NomenclatureForm";
import useNomenclature from "../hooks/useNomenclature";
import useTrigger from "../hooks/useTrigger";

export default function EditNomenclatures() {
  const navigate = useNavigate();

  const { triggerLoading, triggerModel } = useTrigger();

  let {
    state: { itemData },
  } = useLocation();

  const { formData, setFormData, handleInputChange, handleAddSubCategory } =
    useNomenclature(itemData);

  // Determine the endpoint and format based on itemData.type
  const getEndpointAndFormatKey = (type: any) => {
    try {
      switch (type) {
        case "rdv":
          return {
            endpoint: "appointment_types",
            formatKey: "subAppointmentTypes",
          };
        case "folder":
          return { endpoint: "types", formatKey: "subContactTypes" };
        default:
          throw new Error(`Unsupported type: ${type}`);
      }
    } catch (error: any) {
      console.error(error.message);
      throw error;
    }
  };

  const fetchNomenclatureItem = async () => {
    try {
      triggerLoading(true);

      const { endpoint, formatKey } = getEndpointAndFormatKey(itemData.type);

      console.log(`/${endpoint}/${extractIdsFromStrings(itemData.id)}`);
      const response = await CFCApiHydra.get(
        `/${endpoint}/${extractIdsFromStrings(itemData.id)}`
      );
      if (response) {
        setFormData({
          id: response["@id"],
          category: response.name,
          subCategories: response[formatKey] || [],
        });
      } else {
        triggerModel(
          "error",
          "An unexpected error occurred while getting Nomenclature",
          () => {
            navigate("/nomenclatures");
          }
        );
      }
    } catch (err: any) {
      triggerModel("error", err.message, () => {
        navigate("/nomenclatures");
      });
    } finally {
      triggerLoading(false);
    }
  };

  const handleDelete = async (item: any, isParent?: boolean) => {
    try {
      triggerLoading(true);

      const endpoint = (item.id || item["@id"]).replace(/^\/api/, "");
      await CFCApi.delete(`${endpoint}`)
        .then((res: any) => {
          console.log("res:", res);
          if (res.codeStatus === 204) {
            triggerModel("success", "Supprimé avec succès", () => {
              if (isParent) {
                navigate("/nomenclatures");
              }
              triggerModel(null);
            });
          } else {
            throw new Error("error");
          }
        })
        .catch((err: any) => {
          throw new Error(err);
        });
    } catch (err: any) {
      triggerModel("error", err.message);
    } finally {
      await fetchNomenclatureItem();
      triggerLoading(false);
    }
  };

  useEffect(() => {
    fetchNomenclatureItem();
  }, []);

  const handleSave = async () => {
    try {
      // Validation
      const isEmptyCategory = !formData.category.trim();
      const isEmptySubCategory = formData.subCategories.some(
        (subCategory) => !subCategory.name.trim()
      );

      if (isEmptyCategory || isEmptySubCategory) {
        triggerModel(
          "error",
          "Veuillez remplir tous les champs avant de sauvegarder"
        );
        return;
      }

      triggerLoading(true);

      const { endpoint, formatKey } = getEndpointAndFormatKey(itemData.type);

      // Transforming formData structure
      const values = {
        name: formData.category,
        [formatKey]: formData.subCategories.map((subCategory: any) => ({
          ...(subCategory["@id"] && { id: subCategory["@id"] }),
          name: subCategory.name,
        })),
      };

      const response = await CFCApi.put(
        `/${endpoint}/${extractIdsFromStrings(itemData.id)}`,
        values
      );

      if (response) {
        triggerModel("success", "Mis à jour avec succés", () =>
          navigate("/nomenclatures")
        );
      } else {
        triggerModel(
          "error",
          "An unexpected error occurred while updating Nomenclature"
        );
      }
    } catch (error: any) {
      triggerModel("error", error.message);
    } finally {
      triggerLoading(false);
    }
  };

  return (
    <PageWrapper>
      <div className="nomenclatures">
        <h1>{`Modification de type de ${
          itemData.type === "folder" ? "dossier" : "rendez-vous"
        }`}</h1>

        <NomenclatureForm
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
          handleDelete={handleDelete}
          handleAddSubCategory={handleAddSubCategory}
        />

        <div className="button-wrapper form-buttons">
          <Button
            text="RETOUR"
            size="sm"
            onPress={() => navigate("/nomenclatures")}
          />
          <Button text="SAUVEGARDER" size="sm" onPress={handleSave} />
        </div>
      </div>
    </PageWrapper>
  );
}
