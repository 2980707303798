import PageWrapper from "../components/Layout/PageWrapper";
import axios, { AxiosResponse } from "axios";
import Cookie from "js-cookie";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import Button from "../components/Button";
import CFCApi from "../apis";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../state/slices/appSlice";

export default function Settings() {
  const dispatch = useDispatch();
  const handleDownload = () => {
    dispatch(startLoading());
    const url = `${CFCApi.baseUrl}/users/export-all`;
    const token = Cookie.get("token");
    axios
      .request({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
        url,
        responseType: "blob",
      })
      .then(async (response: AxiosResponse<Blob>) => {
        const blobUrl = window.URL.createObjectURL(response.data);
        console.log("blobUrl:", blobUrl);

        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = "all-data.xls";

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
        console.log("cleaned");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  };

  return (
    <PageWrapper>
      <Grid container>
        <Grid item xs={12} md={12} justifyContent="center">
          <div className="setting-actions">
            <h3>Paramètrage</h3>
            <Link className="btn" to="/acces-commerciaux">
              Création / modification COMPTES commerciaux
            </Link>
            <Link className="btn" to="/gestion-des-structures">
              gestion des structures
            </Link>
            <Link className="btn" to="/affectation-contact-dossier">
              modification D’affectation contact/dossier
            </Link>
            <Link className="btn" to="/nomenclatures">
              nomenclatures
            </Link>
            <Button onPress={handleDownload} text="télécharger les données" />
          </div>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
