import { useEffect, useState } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import { Grid } from "@mui/material";
import Icon from "../components/Icon";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CFCApiHydra from "../apis/CFCApiHydra";
import { setModel, startLoading, stopLoading } from "../state/slices/appSlice";
import StatsFilterForm from "../components/StatsFilterForm";

export default function Statistics() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.user.data);
  const isRS = user?.title?.toLowerCase() === "areamanager";
  const isCA = user?.title?.toLowerCase() === "responsiblebusiness";

  const hideFilter = isRS || isCA;

  const [isOpenFilter, setisOpenFilter] = useState(false);

  const [statsData, setStatsData] = useState([]);

  const [DAFilterOptions, setDAFilterOptions] = useState([]);
  const [RSFilterOptions, setRSFilterOptions] = useState([]);
  const [AgencesFilterOptions, setAgencesFilterOptions] = useState([]);

  const openFilter = () => {
    setisOpenFilter(true);
  };

  const handleClose = () => {
    setisOpenFilter(false);
  };

  const handleFilterSubmit = (selectedValues: {
    DAValue: string;
    RSValue: string;
    AgenceValue: string;
  }) => {
    if (
      selectedValues.DAValue ||
      selectedValues.RSValue ||
      selectedValues.AgenceValue
    ) {
      const da = selectedValues.DAValue || "";
      const rs = selectedValues.RSValue || "";
      const agence = selectedValues.AgenceValue || "";
      getStatsData(da, rs, agence);
      setisOpenFilter(false);
    }
  };

  const handleResetFilter = () => {
    getStatsData();
    setisOpenFilter(false);
  };

  const getStatsData = async (da?: string, rs?: string, agence?: string) => {
    try {
      dispatch(startLoading());
      let queryParams = "";
      if (da) {
        queryParams = `?da=${da}`;
      } else if (rs) {
        queryParams = `?rs=${rs}`;
      } else if (agence) {
        queryParams = `?cfcAgency=${agence}`;
      }

      const url = `/statistics/iframes${queryParams}`;
      const stats = await CFCApiHydra.get(url);

      if (stats && stats["hydra:member"]) {
        setStatsData(stats["hydra:member"]);
      } else {
        throw new Error("An unexpected error occurred while getting stats");
      }
    } catch (err: any) {
      dispatch(
        setModel({
          type: "error",
          content: err.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  const getStatsFilterData = async () => {
    try {
      dispatch(startLoading());
      const statsFilter = await CFCApiHydra.get("/statistics/filters");

      if (!statsFilter) {
        throw new Error(
          "An unexpected error occurred while getting stats filter data"
        );
      }

      const daFilterData = statsFilter["da"]
        ? statsFilter["da"]["hydra:member"]
        : [];
      const rsFilterData = statsFilter["rs"]
        ? statsFilter["rs"]["hydra:member"]
        : [];

      const AgenceFilterData = statsFilter["cfcAgence"]
        ? statsFilter["cfcAgence"]["hydra:member"]
        : [];

      const daOptions = daFilterData.map((item: any) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id.toString(),
      }));

      const rsOptions = rsFilterData.map((item: any) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id.toString(),
      }));

      const agencesOptions = AgenceFilterData.map((item: any) => ({
        label: `${item.name}`,
        value: item.id.toString(),
      }));

      setDAFilterOptions(daOptions);
      setRSFilterOptions(rsOptions);
      setAgencesFilterOptions(agencesOptions);
    } catch (err: any) {
      dispatch(
        setModel({
          type: "error",
          content: err.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  useEffect(() => {
    getStatsFilterData();
    getStatsData();
  }, []);

  return (
    <PageWrapper>
      <div className="stats-header">
        <h1>Statistiques</h1>
        {!hideFilter && (
          <div className="filter-btn-wrapper">
            <Button
              icon="filter"
              className="btn-filter"
              text="FILTRES"
              onPress={openFilter}
            />
          </div>
        )}
      </div>

      <div className="stats">
        <Grid columnSpacing={2} container>
          {statsData.map((item: any, index: any) => (
            <Grid item xs={12} md={6} key={index}>
              <div className="stats-item">
                <h2 className="text-center">{item.title}</h2>
                <>
                  <div className="stats-iframe-wrapper">
                    <iframe title="Statistiques" srcDoc={item.content} />
                  </div>
                  <div className="stats-extract">
                    <Button
                      icon="share"
                      text="extraire"
                      to={() =>
                        navigate("/statistics/extract", { state: { item } })
                      }
                    />
                  </div>
                </>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>

      {isOpenFilter && (
        <div className="stats-filter">
          <div className="stats-filter-header">
            <span className="step-btn" onClick={handleClose}>
              <Icon icon="xmark" size={14} />
            </span>
          </div>

          <div className="stats-filter-content">
            <div className="stats-filter-content-inner">
              <StatsFilterForm
                DAOptions={DAFilterOptions}
                RSOptions={RSFilterOptions}
                AgencesOptions={AgencesFilterOptions}
                onSubmit={handleFilterSubmit}
                onResetForm={handleResetFilter}
              />
            </div>
          </div>
        </div>
      )}
    </PageWrapper>
  );
}
