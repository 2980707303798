import { useSelector } from "react-redux";
import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loading from "./components/Loading";
import Modal from "./components/Modal";
import JWTDecode from "./helpers/JWTDecode";
import Login from "./routes/Login";
import ResetPassword from "./routes/ResetPassword";
import ErrorPage from "./routes/ErrorPage";
import UserRoutes from "./routes/UserRoutes";


function App() {
  const user = JWTDecode();

  const stateApp = useSelector((state: any) => state.app);
  const isLoading = stateApp?.loading;
  const isModel = stateApp?.model;

  return (
    <div className="App">
      {isModel && <Modal {...isModel} />}
      {isLoading && <Loading />}
      <BrowserRouter>
        {user ? (
          <UserRoutes />
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
