import { useEffect, useState } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useAttachemts from "../hooks/useAttachemts";
import useTrigger from "../hooks/useTrigger";
import CFCApi from "../apis";
import FormGenerator from "../components/Form";
import { Grid } from "@mui/material";
import Popup from "../components/Popup";
import extractIdsFromStrings from "../helpers/extractIdFromStrings";
import SearchFilter from "../components/SearchFilter";
import Icon from "../components/Icon";
import useDossier from "../hooks/useDossier";
import route_links from "../shared/route_links";
import moment from "moment";

export default function EditDossier({ isHistoy }: { isHistoy?: boolean }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoading = useSelector((state: any) => state.app.loading);
  const currentUser = useSelector((state: any) => state.user?.data);
  const isAdmin: boolean = currentUser?.roles?.includes("ROLE_ADMIN");

  const contactData =
    location?.state?.contactValues || location?.state?.contactData;
  const coEmprunteurValues = location?.state?.coEmprunteurValues;

  const { triggerModel, triggerError, triggerLoading } = useTrigger();
  const {
    attachmentFormFields,
    setAttachmentFormFields,
    handleDelete,
    handleAttachemntClick,
  } = useAttachemts();

  const [popupAtachment, setPopupAtachment] = useState(false);
  const [shareFilter, setShareFilter] = useState(false);
  const [attachments, setAttachments] = useState<any>(null);
  const [attachmentValues, setAttachmentValues] = useState<any>(null);
  const [isEditable, setIsEditable] = useState<any>(false);

  const { formFields, setFormFields, savedUser, updateCoEmprunteur, showForm } =
    useDossier(contactData, coEmprunteurValues, setPopupAtachment);

  useEffect(() => {
    const editableStatus = [
      "unreachable",
      "without_customer_followup",
      "without_extraordinary_followup",
      "rejected_bo",
      "rejected_bank",
      "financed",
    ];

    if (
      (savedUser &&
        editableStatus.includes(savedUser.status) &&
        isAdmin &&
        isHistoy) ||
      !isHistoy
    ) {
      setIsEditable(true);
    }
  }, [savedUser]);

  const handleFormSubmit = async (values: {
    [key: string]: string | string[];
  }) => {
    const updatedValues: any = { ...values };
    const folder = {
      expirationEarnings: updatedValues.expirationEarnings,
      externalApaFolderNumber: updatedValues.externalApaFolderNumber,
      externalApaPackagerNumber: updatedValues.externalApaPackagerNumber,
    };

    delete updatedValues.expirationEarnings;
    delete updatedValues.externalApaFolderNumber;
    delete updatedValues.externalApaPackagerNumber;

    updatedValues.folder = folder;

    try {
      triggerLoading(true);
      if (attachmentValues) {
        await CFCApi.post(
          `/contacts/${extractIdsFromStrings(contactData.id)}/attachments`,
          attachmentValues
        );
      }

      const response = await CFCApi.patch(
        `/contacts/${extractIdsFromStrings(contactData.id)}`,
        updatedValues
      );

      triggerLoading(false);

      if (!response) {
        throw Error("An unexpected error occurred while updating Dossier");
      }

      if (coEmprunteurValues) {
        updateCoEmprunteur(response["@id"], response, coEmprunteurValues);
        return;
      }

      if (response) {
        triggerModel("success", "Mis à jour avec succés", () => {
          navigate(route_links.espace.url);
        });
      }
    } catch (error: any) {
      triggerError(error);
    }
  };

  useEffect(() => {
    if (savedUser?.attachments) {
      const groupedAttachments: any = {};

      savedUser?.attachments.forEach((attachment: any) => {
        const { type, "@id": id, path } = attachment;
        const typeName = type.name;

        if (!groupedAttachments[typeName]) {
          groupedAttachments[typeName] = [];
        }
        groupedAttachments[typeName].push({ "@id": id, path });
      });

      setAttachments(groupedAttachments);
    }
  }, [savedUser?.attachments]);

  const handleShareTrigger = (isShareFilter: boolean) => {
    setShareFilter(isShareFilter);
  };

  const removeFromState = (key: any) => {
    if (attachments) {
      const updatesObj = { ...attachments };

      const attachmentLength = savedUser?.attachments?.length || 0;
      const removedLength = attachments[key]?.length || 0;

      formFields[18].defaultValue = `${attachmentLength - removedLength} files`;
      delete updatesObj[key];

      setAttachments(updatesObj);
      setFormFields(formFields);
    }
  };

  const handleAttachemntChange = async (values: any) => {
    const attachmentLength = savedUser?.attachments?.length || 0;
    const pieceJointeLength = values?.pieceJointe?.length || 0;

    formFields[18].defaultValue = `${
      attachmentLength + pieceJointeLength
    } files`;

    setFormFields(formFields);

    const file_type = values.file_type;
    const pieceJointe = values.pieceJointe;

    if (pieceJointe && pieceJointe?.length > 0) {
      const formData = new FormData();
      formData.append("type", file_type);

      for (let i = 0; i < pieceJointe.length; i++) {
        const file = pieceJointe[i];
        formData.append("file[]", file);
      }

      attachmentFormFields[1].placeholder = `${pieceJointe.length} fichier${
        pieceJointe.length > 1 ? "s" : ""
      }`;
      setAttachmentFormFields(attachmentFormFields);
      setAttachmentValues(formData);
    }
  };

  useEffect(() => {
    if (attachmentFormFields) {
      attachmentFormFields[1].placeholder = "Ajouter des pièces jointes";
      setAttachmentFormFields(attachmentFormFields);
    }
  }, [popupAtachment]);

  return (
    <PageWrapper>
      {!isLoading && savedUser && (
        <>
          <h1>
            Dossier : {`${savedUser.firstName} ${savedUser.lastName}`} à
            completer{" "}
            {savedUser?.createdAt &&
              " (" + moment(savedUser.createdAt).format("DD/MM/YYYY") + ")"}
          </h1>
          {savedUser?.structureName && (
            <h2>Strcuture: {savedUser.structureName}</h2>
          )}
          {isHistoy && savedUser?.updatedAt && (
            <h2>
              Date de cloture: {moment(savedUser.updateAt).format("DD/MM/YYYY")}
            </h2>
          )}
          <Grid container spacing={0}>
            <Grid>
              {showForm && savedUser && (
                <FormGenerator
                  fields={formFields}
                  submitButtonText="Sauvegarder"
                  isReadOnly={!isEditable}
                  widthReturn
                  widthShare
                  shareAction={() => handleShareTrigger(true)}
                  onSubmit={handleFormSubmit}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}

      {!isLoading && popupAtachment && attachmentFormFields && (
        <Popup onClose={() => setPopupAtachment(false)}>
          <div>
            <FormGenerator
              fields={attachmentFormFields}
              shareAction={() => handleShareTrigger(false)}
              onChange={handleAttachemntChange}
            />
            {attachments && (
              <div className="form-attachments">
                <h4>Pièces jointes :</h4>
                <ul>
                  {Object.keys(attachments).map((attch: any, index: number) => (
                    <li className="uploaded-file">
                      <span
                        className="name"
                        onClick={() =>
                          handleAttachemntClick(attachments[attch][0].path)
                        }
                      >
                        {attch}
                      </span>
                      <button
                        className="action"
                        onClick={async () => {
                          const isDeleted = await handleDelete(
                            attachments[attch][0]["@id"]
                          );
                          if (isDeleted) {
                            removeFromState(attch);
                          }
                        }}
                      >
                        <Icon icon="trash" size={14} />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Popup>
      )}

      {shareFilter && savedUser && (
        <div className="sharefilter">
          <SearchFilter
            type="folders"
            userId={extractIdsFromStrings(savedUser["@id"])}
            handleShareClose={() => handleShareTrigger(false)}
          />
        </div>
      )}
    </PageWrapper>
  );
}
