import PageWrapper from "../components/Layout/PageWrapper";
import Grid from "../components/Layout/Grid";

export default function GoogleCalendar() {
  return (
    <PageWrapper>
      <Grid container>
        <Grid item md={12} xs={12}>
          <h2>Google Calendar</h2>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
