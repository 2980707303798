import { useState, useEffect } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setModel, startLoading, stopLoading } from "../state/slices/appSlice";
import CFCApi from "../apis";
import CFCApiHydra from "../apis/CFCApiHydra";
import FormGenerator, { Field } from "../components/Form";
import extractIdFromStrings from "../helpers/extractIdFromStrings";
import useTrigger from "../hooks/useTrigger";

export default function AddStructure() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { triggerModel } = useTrigger();

  useEffect(() => {
    (async () => {
      dispatch(startLoading());
      const typeNames = await CFCApiHydra.get(
        `/structures/levels?pagination=false`
      );

      const structures = await CFCApiHydra.get(
        "/structures/subStructures?pagination=false&dropDown=1&id=0"
      );

      try {
        let formFieldsUpdated = [...formFields];
        if (typeNames["hydra:member"]) {
          formFieldsUpdated[0].options = typeNames["hydra:member"].map(
            (opt: any) => ({
              label: opt.label,
              value: opt.value,
            })
          );
        }
        if (structures.substructures && structures.substructures.length > 0) {
          formFieldsUpdated[1].options = structures.substructures
            .filter((sub: any) => sub)
            .map((opt: any) => ({
              label: opt.name,
              value: opt["@id"],
            }));
        }
        setFormFields(formFieldsUpdated);
      } catch (error) {
        dispatch(
          setModel({
            type: "error",
            content:
              "Une erreur inattendue s'est produite lors de la récupération des données.",
            onClose: () => dispatch(setModel(null)),
          })
        );
      } finally {
        dispatch(stopLoading());
      }

      dispatch(stopLoading());
    })();
  }, []);

  const autoFillRattachementOptions = async (
    val: any,
    formik: any,
    typeNameValue?: string
  ) => {
    if (!val && !typeNameValue) return;

    const childTypeName = typeNameValue || formik.values.typeName;
    if (!childTypeName || childTypeName === "") {
      return;
    }

    let formFieldsUpdated = [...formFields];
    let id;
    if (val) {
      id = extractIdFromStrings(val);
    } else if (formik.values.structure) {
      id = extractIdFromStrings(formik.values.structure);
    } else {
      return;
    }

    dispatch(startLoading());
    const structures = await CFCApiHydra.get(
      `/structures/subStructures?pagination=false&dropDown=1&id=${id}&childTypeName=${childTypeName}`
    );

    if (val === "Site") {
      formFieldsUpdated[2].required = false;
    } else {
      formFieldsUpdated[2].required = true;
    }

    try {
      if (structures.substructures && structures.substructures.length > 0) {
        formFieldsUpdated[2].label = structures.substructures[0].typeName;
        formFieldsUpdated[2].options = structures.substructures.map(
          (opt: any) => ({
            label: opt.name,
            value: opt["@id"],
          })
        );
      } else if (structures?.groupedStructures?.groupNames) {
        let formFieldsUpdated = [...formFields];
        const groupes = structures?.groupedStructures?.groupNames;
        const myGroupedOptions: any = [];
        groupes.forEach((group: any) => {
          const groupName = group.groupeName;
          myGroupedOptions.push({
            label: groupName,
            groupName: groupName,
            options: group.data.map((item: any) => {
              return { label: item.name, value: item["@id"] };
            }),
          });
        });
        formFieldsUpdated[2].label = "Rattachement";
        formFieldsUpdated[2].options = myGroupedOptions;
        setFormFields(formFieldsUpdated);
      } else {
        formFieldsUpdated[2].label = "Rattachement";
        formFieldsUpdated[2].options = [];
      }
    } catch (error) {
      dispatch(
        setModel({
          type: "error",
          content:
            "Une erreur inattendue s'est produite lors de la récupération des données.",
          onClose: () => dispatch(setModel(null)),
        })
      );
    }
    setFormFields(formFieldsUpdated);
    dispatch(stopLoading());
  };

  const handleFormSubmit = async (
    values: {
      [key: string]: string | string[];
    },
    formik: any
  ) => {
    try {
      dispatch(startLoading());
      const response = await CFCApi.post("/structures", values);
      dispatch(stopLoading());
      if (response) {
        if (values.typeName === "Agence") {
          triggerModel(
            "success",
            "Agence créée avec succès, Voulez-vous créer un site ?",
            () => {
              navigate("/apa");
            },
            {
              text: "Oui",
              func: () => {
                formik.resetForm();
                formFields[0].defaultValue = "Site";
                formFields[1].defaultValue = values.structure;
                formFields[2].defaultValue = response["@id"];
                formik.setFieldValue("city", values.city);
                formik.setFieldValue("zipCode", values.zipCode);
                formik.setFieldValue("address", values.address);
                formik.setFieldValue("salePointPhone", values.salePointPhone);
                setFormFields(formFields);
              },
            }
          );
        } else {
          triggerModel("success", `${values.typeName} créé avec succès`, () => {
            navigate("/apa");
          });
        }
      } else {
        dispatch(stopLoading());
        dispatch(
          setModel({
            type: "error",
            content: `Une erreur inattendue s'est produite lors de la création du ${values.typeName}`,
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (error: any) {
      dispatch(stopLoading());
      dispatch(
        setModel({
          type: "error",
          content: error.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    }
  };

  const [formFields, setFormFields] = useState<Field[]>([
    {
      name: "typeName",
      type: "select",
      label: "Création Agence/Site/Groupe",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      noOptionsMessage: () => "Veuillez choisir une structure représentative",
      handleChange: (selected, formik) => {
        autoFillRattachementOptions(null, formik, selected.value);
      },
      required: true,
    },
    {
      name: "structure",
      type: "abstractSelect",
      label: "Structure Représentative",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
    },
    {
      name: "parent",
      type: "abstractSelect",
      label: "Rattachement",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      noOptionsMessage: () => "Veuillez choisir une structure représentative",
      required: true,
      trigger: {
        name: "structure",
        triggerFunc: (val: any, formik) =>
          autoFillRattachementOptions(val, formik),
      },
    },
    {
      name: "name",
      type: "text",
      label: "Nom",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "city",
      type: "text",
      label: "Ville",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "zipCode",
      type: "text",
      label: "Code postal",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "address",
      type: "text",
      label: "Adresse",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "salePointPhone",
      type: "tel",
      label: "Ligne directe PDV",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "salePointEmail",
      type: "email",
      label: "Email PDV",
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
    },
    {
      name: "platformNumber",
      type: "tel",
      label: "Numéro plateforme numérique",
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
    },
    {
      name: "shouldRemunerateApa",
      type: "radioTwoOptions",
      label: "Apporteur d’affaire à rémunérer",
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
    },
  ]);

  return (
    <PageWrapper>
      <h2>Création Site/Agence/Groupe</h2>
      <FormGenerator
        fields={formFields}
        submitButtonText="Sauvegarder"
        widthReturn
        onSubmit={handleFormSubmit}
      />
    </PageWrapper>
  );
}
