import { useEffect, useState } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setModel, startLoading, stopLoading } from "../state/slices/appSlice";
import CFCApi from "../apis";
import CFCApiHydra from "../apis/CFCApiHydra";
import FormGenerator, { Field } from "../components/Form";
import extractIdFromStrings from "../helpers/extractIdFromStrings";
import InfoBox from "../components/InfoBox";
import JWTDecode from "../helpers/JWTDecode";
import Button from "../components/Button";

export default function EditFicheAPA() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dossierEnCoursStat, setDossierEnCoursStat] = useState<any>(null);

  const [showForm, setShowForm] = useState<boolean>(false);
  const [, setIsActiveApa] = useState<any>(false);

  const [isAdmin, setIsAdmin] = useState<any>(null);
  const user: any = JWTDecode();

  const [currentApa, setCurrentApa] = useState<any>(null);

  const autoFillSiteOptions = async (
    val: any,
    formik: any,
    structureRepValue?: string | null,
    APAData?: any
  ) => {
    const sameJob = (val || formik.values.job) === APAData.apa.job["@id"];
    const sameStructureRep =
      (structureRepValue || formik.values.structureRep) ===
      APAData.apa.structureRep;

    if (sameJob && sameStructureRep) {
      formik.setFieldValue("structure", APAData.apa.structure["@id"]);
    } else {
      formik.setFieldValue("structure", null);
    }

    setTimeout(() => {
      formik.setErrors({ ...formik.errors, structure: null });
    }, 0);

    const { structureRep } = formik.values;

    let jobId = val || formik.values.job;
    let structureRepId = structureRepValue || structureRep;
    if (!jobId || !structureRepId) return;

    jobId = extractIdFromStrings(jobId);
    structureRepId = extractIdFromStrings(structureRepId);

    dispatch(startLoading());

    try {
      const structures = await CFCApiHydra.get(
        `/structures?pagination=false&job=${jobId}&rootStructure=${structureRepId}`
      );

      if (structures["hydra:member"]) {
        let formFieldsUpdated = [...formFields];
        formFieldsUpdated[3].label =
          structures["hydra:member"][0]?.typeName || "Site";
        formFieldsUpdated[3].options = structures["hydra:member"].map(
          (opt: any) => ({
            label: opt.name,
            value: opt["@id"],
          })
        );
        setFormFields(formFieldsUpdated);
      } else {
        dispatch(
          setModel({
            type: "error",
            content:
              "Une erreur inattendue s'est produite lors de la récupération des structures.",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (error: any) {
      dispatch(
        setModel({
          type: "error",
          content:
            error.message ||
            "Une erreur inattendue s'est produite lors de l'obtention des structures.",
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  const [formFields, setFormFields] = useState<Field[]>([
    {
      name: "job",
      type: "select",
      label: "Poste",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "status",
      type: "select",
      label: "Status",
      options: [
        { label: "Prospect", value: 0 },
        { label: "Actif", value: 1 },
        { label: "Producteur", value: 2 },
      ],
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
      isDisabled: true,
    },
    {
      name: "structureRep",
      type: "abstractSelect",
      label: "Structure Représentative",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "structure",
      type: "abstractSelect",
      label: "Site",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "civility",
      type: "select",
      label: "Civilité",
      options: [
        { label: "Mr.", value: "Mr." },
        { label: "Mme.", value: "Mme." },
      ],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "lastName",
      type: "text",
      label: "Nom",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "firstName",
      type: "text",
      label: "Prénom",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "email",
      type: "email",
      label: "Email",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "landLine",
      type: "tel",
      label: "Téléphone ligne fixe directe",
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
      isEditable: true,
    },
    {
      name: "phone",
      type: "tel",
      label: "Téléphone portable",
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
      isEditable: true,
    },
    {
      name: "comment",
      type: "textarea",
      label: "Commentaires ",
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
      isEditable: true,
    },
    {
      name: "isStillWorking",
      type: "radioTwoOptions",
      label: "Encore en poste",
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
    },
  ]);

  const handleFormSubmit = async (values: {
    [key: string]: string | string[];
  }) => {
    try {
      dispatch(startLoading());
      const ficheAPAResponse = await CFCApi.patch(
        `/apas/${extractIdFromStrings(contactData.id)}`,
        values
      );

      dispatch(stopLoading());
      if (ficheAPAResponse) {
        dispatch(
          setModel({
            type: "success",
            content: "Mis à jour avec succés",
            onClose: () => {
              navigate("/apa");
              dispatch(setModel(null));
            },
          })
        );
      } else {
        dispatch(
          setModel({
            type: "error",
            content:
              "Une erreur inattendue s'est produite lors de la mise à jour de l'APA.",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (error: any) {
      dispatch(stopLoading());
      dispatch(
        setModel({
          type: "error",
          content: error.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    }
  };

  let {
    state: { contactData },
  } = useLocation();

  useEffect(() => {
    dispatch(startLoading());

    setIsAdmin(user?.roles?.includes("ROLE_ADMIN"));

    const getFormDynamicValues = async () => {
      try {
        Promise.all([
          CFCApiHydra.get("/jobs?pagination=false"),
          CFCApiHydra.get(
            `/apas/${extractIdFromStrings(contactData.id)}/fiche`
          ),
          CFCApiHydra.get(
            "/structures/subStructures?pagination=false&dropDown=1&id=0"
          ),
        ])
          .then(async ([jobsResponse, APAResponse, structuresRep]) => {
            dispatch(stopLoading());
            if (jobsResponse["hydra:member"] && structuresRep.substructures) {
              let formFieldsUpdated = formFields;
              formFieldsUpdated[0].options = jobsResponse["hydra:member"].map(
                (opt: any) => ({
                  label: opt.name,
                  value: opt["@id"],
                })
              );
              formFieldsUpdated[2].options = structuresRep.substructures
                .filter((sub: any) => sub)
                .map((opt: any) => ({
                  label: opt.name,
                  value: opt["@id"],
                }));
              setFormFields(formFieldsUpdated);
            } else {
              dispatch(
                setModel({
                  type: "error",
                  content:
                    "Une erreur inattendue s'est produite lors de la récupération des posts.",
                  onClose: () => dispatch(setModel(null)),
                })
              );
            }

            if (APAResponse) {
              const formFieldsUpdated = formFields;

              if (APAResponse) {
                setCurrentApa(APAResponse);
              }

              for (let key in APAResponse["apa"]) {
                switch (key) {
                  case "job":
                    formFieldsUpdated[0].defaultValue =
                      APAResponse["apa"][key]["@id"];
                    break;
                  case "status":
                    formFieldsUpdated[1].defaultValue = APAResponse["apa"][key];
                    break;
                  case "structureRep":
                    formFieldsUpdated[2].defaultValue = APAResponse["apa"][key];
                    formFieldsUpdated[2].handleChange = (selected, formik) => {
                      autoFillSiteOptions(
                        null,
                        formik,
                        selected?.value,
                        APAResponse
                      );
                    };
                    break;
                  case "structure":
                    dispatch(startLoading());
                    const structures: any = await CFCApiHydra.get(
                      `/structures?pagination=false&job=${extractIdFromStrings(
                        APAResponse["apa"].job["@id"]
                      )}&rootStructure=${extractIdFromStrings(
                        APAResponse["apa"]["structureRep"]
                      )}`
                    );

                    if (structures["hydra:member"]) {
                      let formFieldsUpdated = [...formFields];
                      formFieldsUpdated[3].label =
                        structures["hydra:member"][0]?.typeName;
                      formFieldsUpdated[3].options = structures[
                        "hydra:member"
                      ].map((opt: any) => ({
                        label: opt.name,
                        value: opt["@id"],
                      }));
                    }

                    formFieldsUpdated[3].defaultValue =
                      APAResponse["apa"][key]["@id"];

                    formFieldsUpdated[3].trigger = {
                      name: "job",
                      triggerFunc: (val: any, formik) =>
                        autoFillSiteOptions(val, formik, null, APAResponse),
                    };
                    dispatch(stopLoading());

                    break;
                  case "civility":
                    formFieldsUpdated[4].defaultValue = APAResponse["apa"][key];
                    break;
                  case "lastName":
                    formFieldsUpdated[5].defaultValue = APAResponse["apa"][key];
                    break;
                  case "firstName":
                    formFieldsUpdated[6].defaultValue = APAResponse["apa"][key];
                    break;
                  case "email":
                    formFieldsUpdated[7].defaultValue = APAResponse["apa"][key];
                    break;
                  case "landLine":
                    formFieldsUpdated[8].defaultValue = APAResponse["apa"][key];
                    break;
                  case "phone":
                    formFieldsUpdated[9].defaultValue = APAResponse["apa"][key];
                    break;
                  case "comment":
                    formFieldsUpdated[10].defaultValue = null;
                    break;
                  case "apaComments":
                    formFieldsUpdated[10].details = APAResponse["apa"][key];
                    break;
                  case "isStillWorking":
                    formFieldsUpdated[11].defaultValue =
                      APAResponse["apa"][key];
                    break;
                  default:
                    break;
                }
              }

              const status = APAResponse["apa"]["status"];
              let isActiveApaUpdate;
              let dotColorClassName;

              switch (status) {
                case 0:
                  isActiveApaUpdate = "Prospect";
                  dotColorClassName = "dot-red";
                  break;
                case 1:
                  isActiveApaUpdate = "Actif";
                  dotColorClassName = "dot-orange";
                  break;
                case 2:
                  isActiveApaUpdate = "Producteur";
                  dotColorClassName = "dot-green";
                  break;
                default:
                  isActiveApaUpdate = "";
                  dotColorClassName = "dot-black";
                  break;
              }

              setIsActiveApa(isActiveApaUpdate);

              setDossierEnCoursStat({
                label: "",
                value: [
                  {
                    label: "Dossiers en cours",
                    value: APAResponse["progressFolders"],
                  },
                  {
                    label: isActiveApaUpdate,
                    dot: "full",
                    dotColorClassName: dotColorClassName,
                  },
                  {
                    label: "Contacts générés",
                    value: APAResponse["contactGenerated"],
                    dot: "border",
                  },
                ],
              });

              setFormFields(formFieldsUpdated);
              setShowForm(true);
            } else {
              dispatch(
                setModel({
                  type: "error",
                  content:
                    "Une erreur inattendue s'est produite lors de la récupération des données APA.",
                  onClose: () => dispatch(setModel(null)),
                })
              );
            }
          })
          .catch((error: any) => {
            dispatch(stopLoading());
            dispatch(
              setModel({
                type: "error",
                content: error.message,
                onClose: () => dispatch(setModel(null)),
              })
            );
          });
      } catch (error: any) {
        dispatch(stopLoading());
        dispatch(
          setModel({
            type: "error",
            content: error.message,
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    };
    getFormDynamicValues();
  }, []);

  return (
    <PageWrapper>
      <h1>Fiche APA commercial</h1>
      {currentApa?.apa?.structureName && (
        <h2>Structure: {currentApa.apa.structureName}</h2>
      )}

      {dossierEnCoursStat && (
        <div className="edit-apa-stat">
          <InfoBox stat={dossierEnCoursStat} />
        </div>
      )}

      {showForm && (
        <>
          <FormGenerator
            fields={formFields}
            submitButtonText="Sauvegarder"
            widthReturn
            onSubmit={handleFormSubmit}
          />
          {isAdmin ? (
            <div className="apa-btn-organ">
              <Button
                text="Ajouter un rdv"
                icon="calendar"
                to={() =>
                  navigate("/agenda/add-rdv", {
                    state: {
                      apaId: contactData.id,
                    },
                  })
                }
              />
              <Button
                text="AFFICHER L’ORGANIGRAMME"
                icon="eye"
                onPress={() =>
                  navigate(
                    `/apa/organigram-details?id=${extractIdFromStrings(
                      contactData.id
                    )}&type=apa`,
                    {}
                  )
                }
              />
            </div>
          ) : (
            <div className="apa-btn-organ">
              <Button
                text="Ajouter un rdv"
                icon="calendar"
                to={() =>
                  navigate("/agenda/add-rdv", {
                    state: { apaId: contactData.id },
                  })
                }
              />
            </div>
          )}
        </>
      )}
    </PageWrapper>
  );
}
