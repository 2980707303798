import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import PageWrapper from "../components/Layout/PageWrapper";
import FormGenerator from "../components/Form";
import CFCApi from "../apis/";
import Popup from "../components/Popup";
import extractIdsFromStrings from "../helpers/extractIdFromStrings";
import useContact from "../hooks/useContact";
import useTrigger from "../hooks/useTrigger";
import useAttachemts from "../hooks/useAttachemts";
import moment from "moment";
import SearchFilter from "../components/SearchFilter";
import Icon from "../components/Icon";
import allNullOrUndefinedValues from "../helpers/allNullOrUndefinedValues";
import route_links from "../shared/route_links";

export default function EditContact() {
  const location = useLocation();
  const isLoading = useSelector((state: any) => state.app.loading);

  const contactValues = location?.state?.contactValues;
  const coEmprunteurValues = location?.state?.coEmprunteurValues;
  const contactData = location?.state?.contactData;

  const {
    editContactFormFields,
    setEditContactFormFields,
    updateCoEmprunteur,
    savedUserData,
    attachmentValues,
    setAttachmentValues,
    showForm,
  } = useContact(contactValues || contactData, coEmprunteurValues, null, () =>
    setPopupAtachment(true)
  );
  const {
    attachmentFormFields,
    setAttachmentFormFields,
    handleDelete,
    handleAttachemntClick,
  } = useAttachemts();
  const [attachments, setAttachments] = useState<any>(null);

  const { triggerModel, triggerError, triggerLoading } = useTrigger();

  const navigate = useNavigate();

  const [shareFilter, setShareFilter] = useState(false);
  const [popupAtachment, setPopupAtachment] = useState(false);
  const [currentUploadedValues, setCurrentUploadedValues] = useState<any>(null);

  useEffect(() => {
    if (savedUserData?.attachments) {
      const groupedAttachments: any = {};

      savedUserData?.attachments.forEach((attachment: any) => {
        const { type, "@id": id, path } = attachment;
        const typeName = type.name;

        if (!groupedAttachments[typeName]) {
          groupedAttachments[typeName] = [];
        }
        groupedAttachments[typeName].push({ "@id": id, path });
      });

      setAttachments(groupedAttachments);
    }
  }, [savedUserData?.attachments]);

  useEffect(() => {
    if (attachmentFormFields) {
      attachmentFormFields[1].placeholder = "Ajouter des pièces jointes";
      setAttachmentFormFields(attachmentFormFields);
    }
  }, [popupAtachment]);

  useEffect(() => {
    if (coEmprunteurValues) {
      const { coBorrowerFirstName = "", coBorrowerLastName = "" } =
        coEmprunteurValues;
      const name = `${coBorrowerFirstName} ${coBorrowerLastName}`;
      editContactFormFields[16].defaultValue = name;
    }
  }, [coEmprunteurValues]);

  const handleFormSubmit = async (values: {
    [key: string]: string | string[];
  }) => {
    const updatedValues: any = { ...values };
    const folder = {
      expirationEarnings: updatedValues.expirationEarnings,
      externalApaFolderNumber: updatedValues.externalApaFolderNumber,
      externalApaPackagerNumber: updatedValues.externalApaPackagerNumber,
    };

    delete updatedValues.netTurnover;
    delete updatedValues.expirationEarnings;
    delete updatedValues.externalApaFolderNumber;
    delete updatedValues.externalApaPackagerNumber;

    if (!allNullOrUndefinedValues(folder)) {
      updatedValues.folder = folder;
    }

    try {
      triggerLoading(true);

      if (attachmentValues) {
        await CFCApi.post(
          `/contacts/${extractIdsFromStrings(
            contactData.id || contactValues?.id
          )}/attachments`,
          attachmentValues
        );
      }

      const response = await CFCApi.patch(
        `/contacts/${extractIdsFromStrings(
          contactData?.id || contactValues?.id
        )}`,
        updatedValues
      );

      triggerLoading(false);

      if (!response) {
        throw Error(
          "Une erreur inattendue s'est produite lors de la mise à jour du contact."
        );
      }

      if (coEmprunteurValues) {
        updateCoEmprunteur(response["@id"], response, coEmprunteurValues);
        return;
      }

      if (response) {
        triggerModel(
          "success",
          "Mise à jour avec succés",
          () => {
            navigate(route_links.espace.url);
          },
          updatedValues.status === "home_study"
            ? {
                text: "Ajouter RDV",
                func: () => {
                  navigate("/agenda/add-rdv", {
                    state: { contactId: response["@id"] },
                  });
                },
              }
            : null
        );
        return;
      }
    } catch (error: any) {
      triggerError(error);
    }
  };

  const handleShareTrigger = (isShareFilter: boolean) => {
    setShareFilter(isShareFilter);
  };

  const removeFromState = (key: any) => {
    if (attachments) {
      const updatesObj = { ...attachments };

      const attachmentLength = savedUserData?.attachments?.length || 0;
      const removedLength = attachments[key]?.length || 0;

      editContactFormFields[17].defaultValue = `${
        attachmentLength - removedLength
      } files`;
      delete updatesObj[key];
      delete updatesObj[key];
      setAttachments(updatesObj);
    }
  };

  const handleAttachemnt = (values: any) => {
    const attachmentLength = savedUserData?.attachments?.length || 0;
    const pieceJointeLength = values?.pieceJointe?.length || 0;

    editContactFormFields[17].defaultValue = `${
      attachmentLength + pieceJointeLength
    } files`;

    setEditContactFormFields(editContactFormFields);

    const file_type = values.file_type;
    const pieceJointe = values.pieceJointe;

    if (pieceJointe && pieceJointe?.length > 0) {
      const formData = new FormData();
      formData.append("type", file_type);

      for (let i = 0; i < pieceJointe.length; i++) {
        const file = pieceJointe[i];
        formData.append("file[]", file);
      }

      attachmentFormFields[1].placeholder = `${pieceJointe.length} fichier${
        pieceJointe.length > 1 ? "s" : ""
      }`;
      setAttachmentFormFields(attachmentFormFields);
      setAttachmentValues(formData);
    }
  };

  const handleAttachemntChange = async (values: any) => {
    setCurrentUploadedValues(values);
    handleAttachemnt(values);
  };

  useEffect(() => {
    if (!currentUploadedValues || !popupAtachment) {
      return;
    }
    handleAttachemnt(currentUploadedValues);
  }, [popupAtachment]);

  return (
    <PageWrapper>
      {!isLoading && showForm && (
        <>
          <h1>
            Fiche client
            {savedUserData?.createdAt &&
              " (" + moment(savedUserData.createdAt).format("DD/MM/YYYY") + ")"}
          </h1>
          {savedUserData?.structureName && (
            <h2>Structure: {savedUserData.structureName}</h2>
          )}
          <FormGenerator
            initialValues={contactValues}
            fields={editContactFormFields}
            submitButtonText="Sauvegarder"
            widthReturn
            widthShare
            shareAction={() => handleShareTrigger(true)}
            onSubmit={handleFormSubmit}
          />
        </>
      )}

      {!isLoading && popupAtachment && attachmentFormFields && (
        <Popup onClose={() => setPopupAtachment(false)}>
          <div>
            <FormGenerator
              initialValues={currentUploadedValues}
              fields={attachmentFormFields}
              shareAction={() => handleShareTrigger(false)}
              onChange={handleAttachemntChange}
            />
            {attachments && (
              <div className="form-attachments">
                <h4>Pièces jointes :</h4>
                <ul>
                  {Object.keys(attachments).map((attch: any, index: number) => (
                    <li className="uploaded-file">
                      <span
                        className="name"
                        onClick={() =>
                          handleAttachemntClick(attachments[attch][0].path)
                        }
                      >
                        {attch}
                      </span>
                      <button
                        className="action"
                        onClick={async () => {
                          const isDeleted = await handleDelete(
                            attachments[attch][0]["@id"]
                          );
                          if (isDeleted) {
                            removeFromState(attch);
                          }
                        }}
                      >
                        <Icon icon="trash" size={14} />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Popup>
      )}

      {shareFilter && (
        <div className="sharefilter">
          <SearchFilter
            type="contacts"
            userId={extractIdsFromStrings(savedUserData["@id"])}
            handleShareClose={() => handleShareTrigger(false)}
          />
        </div>
      )}
    </PageWrapper>
  );
}
